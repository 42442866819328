<template>
  <div class="linkedin-card">
    <OptimizedImage :src="imageSrc" :alt="title" class="card-image" />
    <div class="card-content">
      <div class="title-wrapper">
        <h3 class="card-title">{{ title }}</h3>
      </div>
      <div class="description-wrapper">
        <p class="card-description">{{ desc }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import OptimizedImage from '../pages/components/OptimizedImage.vue';

export default {
  name: 'LinkedinCard',
  components: {
    OptimizedImage
  },
  props: {
    imageSrc: String,
    title: String,
    desc: String
  }
}
</script>

<style lang="scss" scoped>
.linkedin-card {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 15px rgba(255, 255, 255, 0.1);
  }

  .card-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }

  .card-content {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .title-wrapper {
    min-height: 100px;
    display: flex;
    align-items: flex-start;
  }

  .card-title {
    margin: 0;
    font-size: 1.25rem;
    font-weight: 600;
    color: #ffffff;
  }

  .description-wrapper {
    margin-top: auto;
  }

  .card-description {
    margin: 0;
    color: #e0e0e0;
    line-height: 1.5;
  }
}
</style>