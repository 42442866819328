<!-- eslint-disable vue/no-unused-vars -->
<template>
  <nav class="navbar">
    <div class="container">
      <router-link class="navbar-brand" to="/presentation">
        <img src="img\logo-jl.png" class="nav-logo" alt="JL Logo">
      </router-link>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'main-navbar'
};
</script>

<style lang="scss">
.navbar {
  background-color: rgba(0, 0, 0, 0.9) !important;
  box-shadow: none !important;
  padding: 1.25rem 0 !important;
  min-height: 80px !important;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  margin: 0 !important;
  border: none !important;

  // Hide mobile menu elements
  .navbar-toggler,
  .navbar-collapse,
  .navbar-translate button,
  .navbar-toggler-bar {
    display: none !important;
  }

  // Hide default responsive menu button
  button[aria-label="Toggle navigation"] {
    display: none !important;
  }

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .navbar-brand {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;
    padding: 0.75rem 0;
  }

  .nav-logo {
    width: 60px;
    height: auto;
    transition: transform 0.3s ease;
    padding: 0.25rem 0;

    &:hover {
      transform: scale(1.05);
    }
  }

  // Additional mobile-specific styles
  @media (max-width: 991px) {
    .navbar-collapse {
      display: none !important;
    }
    
    .navbar-toggler {
      display: none !important;
    }
  }
}
</style>
